import React from "react";
import ReactDOM from "react-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";
import App from "./App";
import { MarkerProvider } from "./contexts/markers";
import { UserProvider } from "./contexts/user";

ReactDOM.render(
    <MarkerProvider>
        <UserProvider>
            <App />
        </UserProvider>
    </MarkerProvider>,
    document.getElementById("root")
);
