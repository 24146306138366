import lashesIcon from "../icons/lashes.svg";
import browsIcon from "../icons/brows.svg";
import nailsIcon from "../icons/nails.svg";
import lipsIcon from "../icons/lips.svg";
import makeupIcon from "../icons/makeup.svg";
import hairIcon from "../icons/hair.svg";
import facialsIcon from "../icons/facials.svg";
import spaIcon from "../icons/spa.svg";
import waxIcon from "../icons/wax.svg";
import sugaringIcon from "../icons/sugaring.svg";

export const categories = [
    { name: "Hair", icon: hairIcon },
    { name: "Nails", icon: nailsIcon },
    { name: "Lashes", icon: lashesIcon },
    { name: "Brows", icon: browsIcon },
    { name: "Lips", icon: lipsIcon },
    { name: "Facials", icon: facialsIcon },
    { name: "Sugaring", icon: sugaringIcon },
    { name: "Waxing", icon: waxIcon },
    { name: "Makeup", icon: makeupIcon },
    { name: "Spa", icon: spaIcon },
];
