import React from "react";
import instagramIcon from "../icons/instagram.svg";
import directionsIcon from "../icons/directions.svg";

export const Address = ({ selectedProvider }) => {
    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    const mapLink = isIOS()
        ? `https://maps.apple.com/?q=${encodeURIComponent(
              selectedProvider.properties.address
          )}`
        : `https://www.google.com/maps?q=${encodeURIComponent(
              selectedProvider.properties.address
          )}`;

    const content = [
        {
            name: selectedProvider.properties.address,
            icon: directionsIcon,
            href: mapLink,
        },
        {
            name: `@${selectedProvider.properties.username}`,
            icon: instagramIcon,
            href: `https://www.instagram.com/${selectedProvider.properties.username}`,
        },
    ];

    return (
        <>
            {content.map((c, index) => (
                <a
                    href={c.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex p-1 gap-2 hover-link w-full text-sm"
                    key={index}
                >
                    <img
                        src={c.icon}
                        alt={`${c.name} icon`}
                        width={18}
                        height={18}
                    />
                    {c.name}
                </a>
            ))}
        </>
    );
};
