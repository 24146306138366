import { supabase } from "../../supabaseClient";

export const addFavorite = async (id, userName) => {
    const { data, error } = await supabase
        .from("favorites")
        .insert([{ username: userName, id: id }]);

    if (error) {
        throw error;
    } else {
        return data;
    }
};

export const deleteFavorite = async (id, userName) => {
    const { data, error } = await supabase
        .from("favorites")
        .delete()
        .eq("id", id)
        .eq("username", userName);

    if (error) {
        throw error;
    } else {
        return data;
    }
};

export const getFavoritesForUser = async (id) => {
    const { data, error } = await supabase
        .from("favorites")
        .select("*")
        .eq("id", id);

    if (error) {
        throw error;
    } else {
        return data;
    }
};
