import React, { useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import { PopupContentSelectedProvider } from "./popup-content-selected-provider";
import { MarkerContext } from "../contexts/markers";

export const CardComponent = ({ provider }) => {
    const { selectedProvider, setSelectedProvider } = useContext(MarkerContext);

    return (
        <Card
            sx={{
                maxWidth: 350,
                borderRadius: "0.5rem",
                boxShadow:
                    selectedProvider === provider ? "0 0 0 3px #9ca9e8" : "",
            }}
        >
            <CardActionArea onClick={() => setSelectedProvider(provider)}>
                {/* <CardMedia
                    component="img"
                    height="40"
                    image={selectedIcon}
                    alt="green iguana"
                /> */}
                <CardContent>
                    <PopupContentSelectedProvider selectedProvider={provider} />
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
