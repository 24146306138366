import React, { useEffect, useState, useContext } from "react";
import filterIcon from "../icons/filter.svg";
import logoIcon from "../icons/logo.svg";
import profileIcon from "../icons/profile.svg";
import { SignUpLoginModal } from "./signup-login-modal";
import { SignUpButton } from "./signup-button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { logOutUser } from "../utils/users/users-utils";
import { supabase } from "../supabaseClient";
import { style } from "../shared/consts";
import { UserContext } from "../contexts/user";

export const Header = ({ showCategories, setShowCategories }) => {
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const { userSuccess, setUserSuccess } = useContext(UserContext);

    const onLogOutClicked = () => {
        logOutUser();
        setUserSuccess(false);
        setShowProfileDropdown(false);
    };

    useEffect(() => {
        if (userSuccess && openSignUpModal) {
            setOpenSignUpModal(false);
        }
    }, [userSuccess, openSignUpModal]);

    useEffect(() => {
        const checkUser = async () => {
            const {
                data: { user },
            } = await supabase.auth.getUser();
            if (user) {
                setUserSuccess(true);
            }
        };
        checkUser();
    }, []);

    return (
        <div className="flex items-center h-fit justify-between px-3 py-2 top-0 bg-white w-full shadow-lg sticky z-10">
            <div className="flex gap-1 items-center text-sm">
                <img src={logoIcon} alt="logo icon" width={26} height={26} />
                <span>find beauty services in your area</span>
            </div>
            <div className="flex items-center gap-3">
                {userSuccess ? (
                    <button
                        onClick={() => {
                            setShowProfileDropdown(!showProfileDropdown);
                            if (showCategories) {
                                setShowCategories(false);
                            }
                        }}
                        type="button"
                    >
                        <img
                            src={profileIcon}
                            alt="profile icon"
                            width={25}
                            height={25}
                        />
                    </button>
                ) : (
                    <div className="flex gap-2">
                        <SignUpButton
                            onClick={() => setOpenSignUpModal(true)}
                        />
                    </div>
                )}
                <Modal
                    open={openSignUpModal}
                    onClose={() => setOpenSignUpModal(false)}
                >
                    <Box sx={style}>
                        <SignUpLoginModal setUserSuccess={setUserSuccess} />
                    </Box>
                </Modal>
                <Button
                    onClick={() => {
                        setShowCategories(!showCategories);
                        if (showProfileDropdown) {
                            setShowProfileDropdown(false);
                        }
                    }}
                    aria-label="filter"
                    className="shrink-0 mr-1"
                >
                    <img
                        src={filterIcon}
                        alt="search icon"
                        width={25}
                        height={25}
                    />
                </Button>
                {showProfileDropdown && (
                    <div className="absolute bg-white z-10 right-0 top-10 py-4 pl-4 shadow-lg">
                        <div className="flex items-center gap-8 pr-11 pb-2 pl-1">
                            <span className="text-base font-medium">
                                profile
                            </span>
                        </div>
                        <div className="flex items-start flex-col gap-2 h-8">
                            <button
                                onClick={onLogOutClicked}
                                type="button"
                                className="flex items-center gap-2 p-1 hover-link text-sm w-16"
                            >
                                log out
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
