import React, { useState, useEffect } from "react";
import favoriteIcon from "../icons/favorite.svg";
import favoritedIcon from "../icons/favorited.svg";
import {
    addFavorite,
    deleteFavorite,
} from "../utils/favorites/favorites-utils";
import { SignUpButton } from "./signup-button";
import { SignUpLoginModal } from "./signup-login-modal";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { supabase } from "../supabaseClient";
import { getUserInfo } from "../utils/users/users-utils";
import Alert from "@mui/material/Alert";
import { style } from "../shared/consts";
import { CardInfo } from "./card-info";

export const PopupContentSelectedProvider = ({ selectedProvider }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [isFavorited, setIsFavorited] = useState(false);
    const [addToFavoriteError, setAddToFavoriteError] = useState(false);
    const [deleteFavoriteError, setDeleteFavoriteError] = useState(false);
    const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const [userSuccess, setUserSuccess] = useState(false);

    const favoriteClicked = async () => {
        const user = await getUserInfo();
        if (!user) {
            setShowPopup(true);
        } else if (isFavorited) {
            deleteFavorite(user.id, selectedProvider.properties.username)
                .then(() => {
                    setIsFavorited(false);
                })
                .catch((error) => {
                    setDeleteFavoriteError(true);
                });
        } else {
            addFavorite(user.id, selectedProvider.properties.username)
                .then(() => {
                    setIsFavorited(true);
                })
                .catch((error) => {
                    setAddToFavoriteError(true);
                });
        }
    };
    //TODO fix this
    // useEffect(() => {
    //     const checkFavoriteStatus = async () => {
    //         const user = await getUserInfo();
    //         if (user) {
    //             const { data, error } = await supabase
    //                 .from("favorites")
    //                 .select("*")
    //                 .eq("id", user.id)
    //                 .eq("username", selectedProvider.properties.username)
    //                 .single();
    //             if (error && error.code !== "PGRST116") {
    //                 throw error;
    //             } else if (data) {
    //                 setIsFavorited(true);
    //             }
    //         }
    //     };
    //     checkFavoriteStatus();
    // }, [selectedProvider.properties.username]);

    useEffect(() => {
        if (userSuccess && openSignUpModal && showPopup) {
            setOpenSignUpModal(false);
            setShowPopup(false);
        }
    }, [userSuccess, openSignUpModal, showPopup]);

    return (
        <>
            <div className="text-base font-medium pb-1 pl-1 flex justify-between items-center">
                {selectedProvider.properties.service.toLowerCase()}
                <button
                    onClick={() => favoriteClicked()}
                    className="outline-none"
                >
                    {isFavorited ? (
                        <img
                            src={favoritedIcon}
                            alt="favorite"
                            width={20}
                            height={20}
                        />
                    ) : (
                        <img
                            src={favoriteIcon}
                            alt="favorited"
                            width={20}
                            height={20}
                        />
                    )}
                </button>
            </div>
            <div>
                {addToFavoriteError && (
                    <Alert variant="outlined" severity="error">
                        failed to add favorite. please try again.
                    </Alert>
                )}
                {deleteFavoriteError && (
                    <Alert variant="outlined" severity="error">
                        failed to delete favorite. please try again.
                    </Alert>
                )}
            </div>

            <Modal open={showPopup} onClose={() => setShowPopup(false)}>
                <Box sx={style}>
                    sign up or log in to add to favorites!
                    <SignUpLoginModal setUserSuccess={setUserSuccess} />
                </Box>
            </Modal>
            <Modal
                open={openSignUpModal}
                onClose={() => setOpenSignUpModal(false)}
            >
                <Box sx={style}>
                    <SignUpLoginModal setUserSuccess={setUserSuccess} />
                </Box>
            </Modal>
            <CardInfo selectedProvider={selectedProvider} />
        </>
    );
};
