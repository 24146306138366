import React, { createContext, useState } from "react";

export const MarkerContext = createContext();

export const MarkerProvider = ({ children }) => {
    const [filteredMarkers, setFilteredMarkers] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");

    return (
        <MarkerContext.Provider
            value={{
                selectedProvider,
                setSelectedProvider,
                filteredMarkers,
                setFilteredMarkers,
                selectedCategory,
                setSelectedCategory,
            }}
        >
            {children}
        </MarkerContext.Provider>
    );
};
