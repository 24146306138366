import React, { useState, useContext } from "react";
import listIcon from "./icons/list.svg";
import mapIcon from "./icons/map.svg";
import Fab from "@mui/material/Fab";
import { useMediaQuery } from "@mui/material";
import { categories } from "./utils/categories";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Header } from "./components/header-component";
import { MapComponent } from "./components/map-component";
import { MarkerContext } from "./contexts/markers";
import { ListComponent } from "./components/list-component";

export default function App() {
    const [showCategoriesPopup, setShowCategoriesPopup] = useState(false);
    const [mapView, setMapView] = useState(true);
    const isMobile = useMediaQuery("(max-width: 640px)");
    const { filteredMarkers, setSelectedCategory } = useContext(MarkerContext);

    return (
        <div>
            <Analytics />
            <SpeedInsights />
            <Header
                showCategories={showCategoriesPopup}
                setShowCategories={() => {
                    setShowCategoriesPopup(!showCategoriesPopup);
                }}
            />
            {isMobile && (
                <Fab
                    onClick={() => setMapView(!mapView)}
                    variant="extended"
                    size="small"
                    sx={{
                        textTransform: "lowercase",
                        position: "absolute",
                        left: "50%",
                        bottom: "5%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#ff9494 !important",
                        color: "white",
                        borderRadius: "0.75rem",
                    }}
                >
                    <div className="flex gap-1 px-2 text-white">
                        <img
                            src={mapView ? listIcon : mapIcon}
                            alt="list"
                            width={18}
                            height={18}
                            style={{ marginRight: "4px" }}
                        />
                        {mapView ? "list" : "map"}
                    </div>
                </Fab>
            )}
            <div className="flex w-full">
                {isMobile ? (
                    <>
                        {mapView ? (
                            <div className="left-container">
                                {showCategoriesPopup && (
                                    <div className="absolute bg-white z-10 right-0 top-10 py-4 pl-4 shadow-lg rounded-lg">
                                        <div className="flex items-center gap-3 pr-3 pb-2 pl-1">
                                            <span className="text-base font-medium">
                                                services
                                            </span>
                                            <button
                                                className="flex text-xs p-1 hover-link"
                                                onClick={() =>
                                                    setSelectedCategory("")
                                                }
                                            >
                                                clear
                                            </button>
                                            <button
                                                className="flex text-xs p-1"
                                                onClick={() =>
                                                    setShowCategoriesPopup(
                                                        false
                                                    )
                                                }
                                            >
                                                close
                                            </button>
                                        </div>
                                        <div className="flex items-start flex-col gap-2 overflow-y-auto h-64">
                                            {categories.map(
                                                (category, index) => (
                                                    <button
                                                        key={index}
                                                        className="flex items-center gap-2 p-1 hover-link text-sm w-full"
                                                        onClick={() =>
                                                            setSelectedCategory(
                                                                category.name
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={category.icon}
                                                            alt="category icon"
                                                            width={20}
                                                            height={20}
                                                        />
                                                        {category.name.toLowerCase()}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                                <MapComponent />
                            </div>
                        ) : (
                            <ListComponent filteredMarkers={filteredMarkers} />
                        )}
                    </>
                ) : (
                    <>
                        <div className="left-container">
                            {showCategoriesPopup && (
                                <div className="absolute bg-white z-10 right-0 top-10 py-4 pl-4 shadow-lg rounded-lg">
                                    <div className="flex items-center gap-4 pr-4 pb-2 pl-1">
                                        <span className="text-base font-medium">
                                            services
                                        </span>
                                        <button
                                            className="flex text-xs p-1 hover-link"
                                            onClick={() =>
                                                setSelectedCategory("")
                                            }
                                        >
                                            clear
                                        </button>
                                        <button
                                            className="flex text-xs p-1"
                                            onClick={() =>
                                                setShowCategoriesPopup(false)
                                            }
                                        >
                                            close
                                        </button>
                                    </div>
                                    <div className="flex items-start flex-col gap-2 overflow-y-auto h-64">
                                        {categories.map((category, index) => (
                                            <button
                                                key={index}
                                                className="flex items-center gap-2 p-1 hover-link text-sm w-full"
                                                onClick={() =>
                                                    setSelectedCategory(
                                                        category.name
                                                    )
                                                }
                                            >
                                                <img
                                                    src={category.icon}
                                                    alt="category icon"
                                                    width={20}
                                                    height={20}
                                                />
                                                {category.name.toLowerCase()}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <MapComponent />
                        </div>
                        <ListComponent filteredMarkers={filteredMarkers} />
                    </>
                )}
            </div>
        </div>
    );
}
