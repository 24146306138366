import React from "react";

export const LoginButton = ({ login, className = "" }) => {
    return (
        <button
            onClick={login}
            type="button"
            className={`w-20 border-solid border rounded-lg text-sm p-1 ${className}`}
            style={{
                borderColor: "#ff9494",
                color: "#ff9494",
            }}
        >
            log in
        </button>
    );
};
