import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userSuccess, setUserSuccess] = useState(false);

    return (
        <UserContext.Provider value={{ userSuccess, setUserSuccess }}>
            {children}
        </UserContext.Provider>
    );
};
