import React from "react";
import { Address } from "./address-component";
import emailIcon from "../icons/email.svg";
import phoneIcon from "../icons/phone.svg";
import calendarIcon from "../icons/calendar.svg";

export const CardInfo = ({ selectedProvider }) => {
    return (
        <div className="flex flex-col">
            <Address selectedProvider={selectedProvider} />
            {selectedProvider.properties.phone && (
                <a
                    href={`tel:${selectedProvider.properties.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex p-1 gap-2 hover-link w-full text-sm"
                >
                    <img
                        src={phoneIcon}
                        alt="phone icon"
                        width={18}
                        height={18}
                    />
                    {selectedProvider.properties.phone}
                </a>
            )}
            {selectedProvider.properties.email && (
                <a
                    href={`mailto:${selectedProvider.properties.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex p-1 ml-0.5 gap-2 hover-link w-full text-sm"
                >
                    <img
                        src={emailIcon}
                        alt="email icon"
                        width={18}
                        height={18}
                    />
                    {selectedProvider.properties.email}
                </a>
            )}
            {selectedProvider.properties.link && (
                <a
                    href={selectedProvider.properties.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex p-1 ml-0.5 gap-2 hover-link w-full text-sm"
                >
                    <img
                        src={calendarIcon}
                        alt="email icon"
                        width={18}
                        height={18}
                    />
                    <span>book now!</span>
                </a>
            )}
        </div>
    );
};
