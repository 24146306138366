import React, { useState, useRef, useContext, useEffect } from "react";
import Map, { GeolocateControl, Marker, Popup } from "react-map-gl";
import providers from "../data/beauty-service-providers.json";
import markerIcon from "../icons/marker.svg";
import selectedProviderIcon from "../icons/current.svg";
import { PopupContentSelectedProvider } from "./popup-content-selected-provider";
import { MarkerContext } from "../contexts/markers";

export const MapComponent = () => {
    const mapRef = useRef(null);
    const {
        selectedCategory,
        selectedProvider,
        setSelectedProvider,
        filteredMarkers,
        setFilteredMarkers,
    } = useContext(MarkerContext);
    const [originalFilteredMarkers, setOriginalFilteredMarkers] = useState([]);
    const [viewport] = useState({
        longitude: -117.853104,
        latitude: 33.787914,
        zoom: 9,
    });

    const initBounds = () => {
        return {
            sw: {
                lat: viewport.latitude - 1,
                lng: viewport.longitude - 1,
            },
            ne: {
                lat: viewport.latitude + 1,
                lng: viewport.longitude + 1,
            },
        };
    };

    const getViewportBounds = () => {
        const init = initBounds();
        if (mapRef.current) {
            const bounds = mapRef.current.getMap().getBounds();
            return {
                sw: bounds.getSouthWest(),
                ne: bounds.getNorthEast(),
            };
        }
        return init;
    };

    const filterMarkersWithinBounds = (markers, bounds) => {
        return markers.filter((marker) => {
            const [longitude, latitude] = marker.geometry.coordinates;
            return (
                latitude >= bounds.sw.lat &&
                latitude <= bounds.ne.lat &&
                longitude >= bounds.sw.lng &&
                longitude <= bounds.ne.lng
            );
        });
    };

    const filterAndSetBounds = () => {
        const bounds = getViewportBounds();
        if (bounds) {
            const newVisibleMarkers = filterMarkersWithinBounds(
                providers.features,
                bounds
            );
            setOriginalFilteredMarkers(newVisibleMarkers);
            setFilteredMarkers(newVisibleMarkers);
        }
    };

    const onViewportChange = () => {
        filterAndSetBounds();
        if (selectedCategory !== "") {
            const visibleMarkersWithSelectedCategory = filterByCategory(
                originalFilteredMarkers,
                selectedCategory
            );
            setFilteredMarkers(visibleMarkersWithSelectedCategory);
        } else {
            filterAndSetBounds();
        }
    };

    const filterByCategory = (markers, category) => {
        if (category === "") return filteredMarkers;
        return markers.filter(
            (marker) => marker.properties.service === category
        );
    };

    useEffect(() => {
        if (selectedCategory !== "") {
            const newFilteredMarkers = filterByCategory(
                originalFilteredMarkers,
                selectedCategory
            );
            setFilteredMarkers(newFilteredMarkers);
        } else {
            filterAndSetBounds();
        }
        // eslint-disable-next-line
    }, [selectedCategory]);

    useEffect(() => {
        const bounds = initBounds();
        const initialFilteredMarkers = filterMarkersWithinBounds(
            providers.features,
            bounds
        );
        setFilteredMarkers(initialFilteredMarkers);
        // eslint-disable-next-line
    }, [viewport]);

    return (
        <Map
            ref={mapRef}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={viewport}
            onMoveEnd={() => {
                onViewportChange();
            }}
            style={{ height: "100vh" }}
            mapStyle="mapbox://styles/vanitymap/clzudvkxu00hn01r8cwwlfdu1"
        >
            <GeolocateControl
                trackUserLocation={true}
                positionOptions={{
                    enableHighAccuracy: true,
                }}
                auto
            />
            {filteredMarkers.map((provider) => (
                <Marker
                    longitude={provider.geometry.coordinates[0]}
                    latitude={provider.geometry.coordinates[1]}
                    anchor="top"
                    onClick={() => setSelectedProvider(provider)}
                >
                    <img
                        src={
                            selectedProvider === provider
                                ? selectedProviderIcon
                                : markerIcon
                        }
                        alt={provider.properties.username}
                        width={28}
                        height={28}
                    />
                </Marker>
            ))}
            {selectedProvider && (
                <Popup
                    longitude={selectedProvider.geometry.coordinates[0]}
                    latitude={selectedProvider.geometry.coordinates[1]}
                    anchor="bottom"
                    onClose={() => {
                        setSelectedProvider(null);
                    }}
                    maxWidth={4}
                >
                    <PopupContentSelectedProvider
                        selectedProvider={selectedProvider}
                    />
                </Popup>
            )}
        </Map>
    );
};
