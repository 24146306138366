import React from "react";

export const SignUpButton = ({ onClick, className = "" }) => {
    return (
        <button
            onClick={onClick}
            type="button"
            className={`w-20 border-solid border rounded-lg text-sm text-white p-1 ${className}`}
            style={{
                backgroundColor: "#ff9494",
                borderColor: "#ff9494",
            }}
        >
            sign up
        </button>
    );
};
