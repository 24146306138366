import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SignUpButton } from "./signup-button";
import { LoginButton } from "./login-button";
import { addNewUser } from "../utils/users/users-utils";

export const SignUpLoginModal = ({ setUserSuccess }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [tab, setTab] = useState(0);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [nameError, setNameError] = useState("");
    const [loginFailError, setLoginFailError] = useState("");
    const signUpTab = tab === 0;

    // TODO: cleanup - refactor all these into helper file
    const validateEmail = (email) => {
        // Regular expression to check if the email follows standard email format
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const validatePassword = (password) => {
        // Minimum 8 characters and at least one number
        const regex = /^(?=.*\d)[A-Za-z\d]{8,}$/;

        return regex.test(password);
    };

    const validateInputs = () => {
        let valid = true;

        // validate name
        if (name === "") {
            setNameError("please enter a name");
            valid = false;
        } else {
            setNameError("");
        }

        // validate email
        if (!validateEmail(email)) {
            setEmailError("please enter a valid email");
            valid = false;
        } else {
            setEmailError("");
        }

        // validate password
        if (!validatePassword(password)) {
            setPasswordError(
                "password must be at least 8 characters long and contain at least one number"
            );
            valid = false;
        } else {
            setPasswordError("");
        }

        return valid;
    };

    const handleBlur = (field) => {
        switch (field) {
            case "name":
                if (name === "") {
                    setNameError("please enter a name");
                } else {
                    setNameError("");
                }
                break;
            case "email":
                if (!validateEmail(email)) {
                    setEmailError("please enter a valid email");
                } else {
                    setEmailError("");
                }
                break;
            case "password":
                if (!validatePassword(password)) {
                    setPasswordError(
                        "password must be at least 8 characters long and contain at least one number"
                    );
                } else {
                    setPasswordError("");
                }
                break;
            default:
                break;
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!validateInputs()) {
            return;
        }
        const {
            data: { user },
            error,
        } = await supabase.auth.signUp({
            email,
            password,
        });
        if (user) {
            setUserSuccess(true);
            addNewUser(user, name);
        }
        if (error) {
            throw error.message;
        }
    };

    const handleLogIn = async (e) => {
        e.preventDefault();
        const {
            data: { user },
            error,
        } = await supabase.auth.signInWithPassword({ email, password });

        if (user) {
            setUserSuccess(true);
        }
        if (error) {
            setLoginFailError("incorrect email or password");
        }
    };

    return (
        <div>
            <Box sx={{ width: "100%" }}>
                <Box>
                    <Tabs
                        value={tab}
                        onChange={(event, newValue) => {
                            event.preventDefault();
                            setTab(newValue);
                        }}
                        aria-label="sign up or log in"
                    >
                        <Tab label="sign up" />
                        <Tab label="log in" />
                    </Tabs>
                    <div className="flex flex-col gap-2">
                        {signUpTab && (
                            <TextField
                                id="name"
                                label="name"
                                variant="standard"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => handleBlur("name")}
                                required
                                error={!!nameError}
                                helperText={nameError}
                            />
                        )}
                        <TextField
                            id="email"
                            label="email"
                            type="email"
                            variant="standard"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() =>
                                signUpTab ? handleBlur("email") : {}
                            }
                            required
                            error={signUpTab ? !!emailError : !!loginFailError}
                            helperText={signUpTab ? emailError : loginFailError}
                        />
                        <TextField
                            id="password"
                            label="password"
                            type="password"
                            variant="standard"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={() =>
                                signUpTab ? handleBlur("password") : {}
                            }
                            required
                            error={
                                signUpTab ? !!passwordError : !!loginFailError
                            }
                            helperText={
                                signUpTab ? passwordError : loginFailError
                            }
                        />
                        <div className="flex justify-center mt-3">
                            {signUpTab ? (
                                <SignUpButton onClick={handleSignUp} />
                            ) : (
                                <LoginButton login={handleLogIn} />
                            )}
                        </div>
                    </div>
                </Box>
            </Box>
        </div>
    );
};
