import { supabase } from "../../supabaseClient";

export const fetchUsers = async () => {
    const { data, error } = await supabase.from("users").select();
    if (error) {
        throw error;
    } else {
        return data;
    }
};

export const addNewUser = async (user, name) => {
    const { data, error } = await supabase.from("users").insert([
        {
            id: user.id,
            name: name,
            email: user.email,
        },
    ]);
    if (error) {
        throw error;
    } else {
        return data;
    }
};

export const logOutUser = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
        throw error;
    }
};

export const getUserInfo = async () => {
    const {
        data: { user },
    } = await supabase.auth.getUser();

    if (user) {
        return user;
    } else {
        console.log("No user signed in");
    }
};
