import React, { useContext, useState } from "react";
import { CardComponent } from "./card-component";
import { UserContext } from "../contexts/user";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const ListComponent = ({ filteredMarkers }) => {
    const { userSuccess } = useContext(UserContext);
    const [tab, setTab] = useState(0);
    const listTab = tab === 0;

    return (
        <div
            className="right-container overflow-y-auto p-6 flex flex-col items-center"
            style={{ height: "100vh" }}
        >
            {userSuccess ? (
                <>
                    <Tabs
                        value={tab}
                        onChange={(event, newValue) => {
                            event.preventDefault();
                            setTab(newValue);
                        }}
                        sx={{ marginBottom: "1rem" }}
                    >
                        <Tab label="list" />
                        <Tab label="favorites" />
                    </Tabs>
                    <div className="flex flex-col gap-4">
                        {listTab ? (
                            <>
                                {filteredMarkers.map((provider, index) => (
                                    <CardComponent
                                        provider={provider}
                                        key={index}
                                    />
                                ))}
                            </>
                        ) : (
                            <div>favorites</div>
                        )}
                    </div>
                </>
            ) : (
                <div className="flex flex-col gap-4">
                    {filteredMarkers.map((provider, index) => (
                        <CardComponent provider={provider} key={index} />
                    ))}
                </div>
            )}
        </div>
    );
};
